@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

:root {
  --main: #012113;
  --main1: #000;
  --main2: #fff;
  --primary: #014f28;
  --primary1: #659940;
  --font: "Archivo", sans-serif;
  --regular: 400;
  --bold: 700;
  --black: 900;
}

body {
  background: var(--main);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.463);
  backdrop-filter: blur(5px);
  padding: 0px 30px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 4;
  transition: 0.4s ease-in-out;
}

nav:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

nav .logo {
  padding: 10px 0px;
}

.bars i {
  color: var(--main1);
  font-size: 20px;
  transition: none;
  display: none;
}

.bars i:hover {
  color: var(--primary1);
}

nav .logo .link {
  width: 100px;
  padding-top: 10px;
}

.logo .link img {
  width: 50px;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

nav ul li {
  list-style: none;
}

nav ul li .link {
  font-size: 14px;
  font-family: "Archivo", sans-serif;
  font-weight: var(--bold);
  color: rgb(0, 0, 0);
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
}

nav ul li .home {
  display: none;
}

nav ul li .link:hover {
  color: var(--primary1);
}

nav ul li .hlink {
  border-bottom: 4px solid var(--main);
}

nav .links .order {
  width: 100px;
  height: 35px;
  background: black;
  color: var(--main);
  font-family: calibri;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.home-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 80px;
  background: var(--main2);
  border-bottom-right-radius: 200px;
}

.home-hero .text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 500px;
  gap: 30px;
}

.home-hero .text h1 {
  font-size: 58px;
  font-family: var(--font);
  font-weight: var(--black);
  color: var(--main1);
}

.home-hero .text h1 span {
  color: var(--primary1);
}

.home-hero .text p {
  font-size: 22px;
  font-weight: var(--regular);
  font-family: var(--font);
  color: rgb(64, 64, 64);
}

.home-hero .text button {
  background: none;
  font-family: var(--font);
  font-size: 15px;
  font-weight: var(--bold);
  text-transform: uppercase;
  border: 3px solid #000000;
  outline: none;
  padding: 10px;
  min-width: 200px;
  cursor: pointer;
  color: var(--main1);
  transition: color 0.4s linear;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 3;
}

.home-hero .text button:hover {
  color: #000000;
  border: 3px solid var(--primary1);
}

.home-hero .text button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #659940;
  z-index: -1;
  transition: transform 0.5s;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleX(0);
  /* color: white; */
}

.home-hero .text button:hover::before {
  transform: scaleX(1);
  color: white;
}

.home-hero .image {
  width: 700px;
  height: 450px;
  overflow: hidden;
  z-index: 1;
  background: url(../public/images/hero_land.jpg);
  background-size: cover;
}

.home-hero .image img {
  width: 100%;
  height: max-content;
  height: 450px;
}

.home-hero .fixed img {
  position: absolute;
  top: 0;
  margin-top: 260px;
  right: 0;
  width: 400px;
  opacity: 0.4;
  z-index: 0;
}

.home-hero .top img {
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  opacity: 0.4;
  z-index: 2;
}

.about_land {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  padding: 100px;
  gap: 20px;
}

.about_land .text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
}

.about_land .text p {
  font-size: 15px;
  color: var(--primary1);
  font-family: var(--font);
  font-weight: var(--regular);
}

.about_land .text span {
  font-size: 20px;
  color: var(--main2);
  font-family: var(--font);
  font-weight: var(--bold);
}

.about_land .text h1 {
  font-size: 17px;
  color: var(--main2);
  font-family: var(--font);
  font-weight: var(--regular);
  line-height: 24px;
}

.about_land .text button {
  background: none;
  font-family: var(--font);
  font-size: 15px;
  font-weight: var(--bold);
  text-transform: uppercase;
  border: 3px solid var(--main2);
  outline: none;
  padding: 10px;
  min-width: 200px;
  cursor: pointer;
  color: var(--main2);
  transition: color 0.4s linear;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 3;
}

.about_land .text button:hover {
  color: #000;
}

.about_land .text button::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--main2);
  z-index: -1;
  transition: transform 0.5s;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleX(0);
  /* color: white; */
}

.about_land .text button:hover::before {
  transform: scaleX(1);
}

.about_land .image {
  width: 600px;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.about_land .image img {
  width: 100%;
}

.why-us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 70px 100px;
  padding-top: 20px;
  z-index: 1;
}

.why-us .head {
  color: var(--main);
  text-align: center;
  color: var(--main2);
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 50px;
  width: 100%;
  padding-bottom: 30px;
}

.why-us .frame {
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid white;
  gap: 20px;
  overflow: hidden;
}

.why-us .frame .container {
  background: var(--main2);
  width: 300px;
  height: 300px;
  border-radius: 20px 0px 20px 0px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.why-us .frame .container .top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.why-us .frame .container .top i {
  color: var(--main);
  font-size: 60px;
}

.why-us .frame .container .text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-direction: column;
}

.why-us .frame .container .text h1 {
  font-size: 20px;
  color: var(--main1);
  text-align: center;
  font-family: var(--font);
  font-weight: var(--bold);
}

.why-us .frame .container .text p {
  font-size: 14px;
  color: var(--main);
  text-align: center;
  font-family: var(--font);
  font-weight: var(--regular);
}

.why-us .fixed img {
  position: absolute;
  top: 0;
  margin-top: 1307px;
  right: 0;
  width: 400px;
  opacity: 0.4;
  z-index: -1;
  opacity: 0.2;
}

.white {
  background: url(../public/images/bg.jpg);
  background-size: cover;
}

.service_land {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px;
  padding-bottom: 30px;
  border-bottom: 2px solid var(--main);
  background: none;
  gap: 20px;
}

.service_land .image {
  height: 600px;
  width: 500px;
  background: var(--primary1);
  border-radius: 0px 70px 0px 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_land .image img {
  width: 100%;
}

.service_land .text {
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 50px;
  z-index: 3;
}

.service_land .text .text-top h1 {
  font-size: 18px;
  color: var(--primary1);
  font-family: var(--font);
  font-weight: var(--regular);
}

.service_land .text .text-top p {
  font-size: 60px;
  color: var(--main);
  font-family: var(--font);
  font-weight: var(--bold);
}

.service_land .text .mySwiper {
  display: flex;
  justify-content: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 500px;
}

.service_land .text .mySwiper .gap {
  display: flex;
  justify-content: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.service_land .text .mySwiper h1 {
  font-family: var(--font);
  font-size: 20px;
  color: var(--main);
}

.service_land .text .mySwiper p {
  font-family: var(--font);
  font-size: 20px;
  color: var(--main);
  max-width: 500px;
}

.service_land .text button {
  background: none;
  font-family: var(--font);
  font-size: 15px;
  font-weight: var(--bold);
  text-transform: uppercase;
  border: 3px solid var(--main);
  outline: none;
  padding: 10px;
  min-width: 200px;
  cursor: pointer;
  color: var(--main);
  transition: color 0.4s linear;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 1;
}

.service_land .text button:hover {
  color: #fff;
}

.service_land .text button::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--main);
  z-index: -1;
  transition: transform 0.5s;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleY(0);
  /* color: white; */
}

.service_land .text button:hover::before {
  transform: scaleY(1);
}

.equipment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 100px;
}

.equipment .text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 700px;
  gap: 20px;
}

.equipment .text p {
  font-size: 17px;
  color: var(--main);
  font-family: var(--font);
  font-weight: var(--bold);
}

.equipment .text h1 {
  font-size: 18px;
  color: var(--main);
  font-family: var(--font);
  font-weight: var(--regular);
}

.equipment .text button {
  background: none;
  font-family: var(--font);
  font-size: 15px;
  font-weight: var(--bold);
  text-transform: uppercase;
  border: 3px solid var(--main);
  outline: none;
  padding: 10px;
  min-width: 200px;
  cursor: pointer;
  color: var(--main);
  transition: color 0.4s linear;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 3;
}

.equipment .text button:hover {
  color: #ffffff;
}

.equipment .text button::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--main);
  z-index: -1;
  transition: transform 0.5s;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleX(0);
  /* color: white; */
}

.equipment .text button:hover::before {
  transform: scaleX(1);
}

.equipment .image img {
  width: 300px;
}

.hero_about {
  background: var(--primary1);
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 100%;
  height: 500px;
}

.hero_about .image {
  width: 100%;
  height: 500px;
  overflow: hidden;
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.hero_about .image img {
  width: 120%;
  transition: 0.4s ease-in-out;
  filter: blur(5px);
}

.hero_about .image img:hover {
  filter: blur(10px);
}

.hero_about .text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 3;
  /* margin-top: -; */
  padding: 100px;
  gap: 5px;
}

.hero_about .text h1 {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 60px;
  color: var(--main);
}

.hero_about .text p {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 17px;
  color: var(--main2);
}

.about_about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--main2);
  padding: 100px 0px;
  gap: 100px;
}

.about_about .text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  padding: 0px 100px;
  backdrop-filter: blur(7px);
  width: 100%;
}

.about_about .text p {
  font-family: var(--font);
  color: var(--main1);
  font-size: 17px;
  font-weight: var(--regular);
  width: 50%;
  text-align: justify;
}

.about_about .mission {
  width: 100%;
  height: 350px;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9) 50%,
      rgba(0, 0, 0, 0.9) 50%
    ),
    url(../public/images/hero_land1.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding: 100px;
}

.about_about .mission p {
  font-family: var(--font);
  color: var(--main);
  padding: 7px;
  font-size: 20px;
  font-weight: var(--regular);
  text-transform: uppercase;
  background-color: var(--main2);
}

.about_about .mission strong {
  font-family: var(--font);
  color: var(--main2);
  font-size: 20px;
  font-weight: var(--bold);
}

.service_about {
  background: var(--main2);
  padding-top: 0;
  padding-bottom: 100px;
}

.service_about .text .text-top p {
  font-size: 50px;
}

.service_about .text .paragraph {
  font-size: 17px;
  font-family: var(--font);
  color: var(--main);
  font-weight: var(--regular);
}

.hero_contact {
  background: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 0, 0, 0), #000000b4),
    url(../public/images/contact_hero.jpg);
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 100%;
  height: 400px;
}

.hero_contact .image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.hero_contact .image img {
  width: 120%;
  transition: 0.4s ease-in-out;
}

.hero_contact .image img:hover {
  filter: blur(10px);
}

.hero_contact .text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 100px;
  gap: 5px;
}

.hero_contact .text h1 {
  font-family: var(--font);
  font-weight: var(--black);
  font-size: 25px;
  color: var(--main);
}

.hero_contact .text p {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 36px;
  color: var(--main);
}

.form_container {
  background: white;
  padding: 100px;
}

.form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: var(--main);
  gap: 30px;
  padding: 30px;
  border-radius: 5px;
}

.form .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;
  gap: 6px;
}

.form .form-group label {
  font-family: var(--font);
  font-size: 16px;
  color: var(--main2);
}

.form .form-group input {
  font-family: var(--font);
  font-size: 16px;
  color: var(--main);
  border: none;
  outline: none;
  background: white;
  min-width: 100%;
  padding: 5px;
  z-index: 3;
}

.form .form-group select {
  font-family: var(--font);
  font-size: 16px;
  color: var(--main);
  border: none;
  outline: none;
  background: white;
  min-width: 100%;
  padding: 5px;
  z-index: 3;
}

.form .button {
  font-family: var(--font);
  font-size: 16px;
  color: var(--main2);
  border: 1px solid var(--main2);
  outline: none;
  background: var(--main);
  width: 130px;
  height: 40px;
  padding: 5px;
  z-index: 3;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.674);
  transition: 0.3s ease-in-out;
}

.form .button:hover {
  box-shadow: 0px 0px 30px rgb(255, 255, 255);
}

.form_container .fixed {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
}

.form_container .fixed img {
  width: 100%;
}

.service_hero {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 400px;
  z-index: 3;
}

.service_hero img {
  transition: 0.4s ease-in-out;
  width: 120%;
}

.service_hero img:hover {
  filter: blur(6px);
}

.service_service {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../public/images/bg.jpg), white;
  width: 100%;
  padding: 100px;
  gap: 100px;
}

.service_service .service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  z-index: 3;
  width: 100%;
}

.service_service .service_swap {
  flex-direction: row-reverse;
}

.service_service .service .image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 600px;
  width: 500px;
  overflow: hidden;
  border-radius: 20px;
}

.service_service .service .image img {
  width: 100%;
  height: 100%;
}

.service_service .service .text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 400px;
  flex-direction: column;
  gap: 20px;
}

.service_service .service .text h2 {
  color: var(--main);
  font-size: 23px;
  font-weight: var(--bold);
  font-family: var(--font);
}

.service_service .service .text p {
  color: var(--main1);
  font-size: 16px;
  font-weight: var(--regular);
  font-family: var(--font);
  line-height: 20px;
  text-align: justify;
}

.service_service .equipment {
  background: var(--main);
  border-radius: 10px;
}

.service_service .equipment .text p {
  color: var(--main2);
}

.service_service .equipment .text h1 {
  color: var(--main2);
}

.service_service .equipment .text button {
  color: var(--main2);
  border: 2px solid var(--main2);
}

.service_service .equipment .text button:hover {
  color: var(--main);
}

.service_service .equipment .text button::before {
  background: var(--main2);
  color: var(--main);
}

#background-wrap {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

/* KEYFRAMES */

@-webkit-keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }
  100% {
    margin-top: -100px;
  }
}

@-moz-keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }
  100% {
    margin-top: -100px;
  }
}

@keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }
  100% {
    margin-top: -100px;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@-moz-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

/* ANIMATIONS */

.x1 {
  -webkit-animation: animateBubble 25s linear infinite,
    sideWays 10s ease-in-out infinite alternate;
  -moz-animation: animateBubble 25s linear infinite,
    sideWays 10s ease-in-out infinite alternate;
  animation: animateBubble 25s linear infinite,
    sideWays 10s ease-in-out infinite alternate;

  left: -5%;
  top: 5%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x2 {
  -webkit-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;

  left: 5%;
  top: 80%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x3 {
  -webkit-animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;

  left: 10%;
  top: 40%;

  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.x4 {
  -webkit-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;

  left: 20%;
  top: 0;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x5 {
  -webkit-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;

  left: 30%;
  top: 50%;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}

.x6 {
  -webkit-animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;

  left: 50%;
  top: 0;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.x7 {
  -webkit-animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;

  left: 65%;
  top: 70%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x8 {
  -webkit-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;

  left: 80%;
  top: 10%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x9 {
  -webkit-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;

  left: 90%;
  top: 50%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x10 {
  -webkit-animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;

  left: 80%;
  top: 80%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

/* OBJECTS */

.bubble {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);

  height: 200px;
  position: absolute;
  width: 200px;
}

.bubble:after {
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(255, 255, 255, 0.5)),
    color-stop(70%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  ); /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  ); /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);

  content: "";
  height: 180px;
  left: 10px;
  position: absolute;
  width: 180px;
}

footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 100px;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  z-index: 1;
}

footer .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 50px;
  z-index: 3;
}

footer .top img {
  width: 75px;
}

footer .top ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

footer .top ul li {
  list-style: none;
  text-align: center;
  color: var(--primary1);
  font-family: var(--font);
  font-size: 15px;
  font-weight: var(--bold);
}

footer .top ul li .links {
  color: var(--main2);
  font-weight: var(--regular);
}

footer .bottom {
  text-align: center;
  color: var(--primary1);
  font-family: var(--font);
  font-size: 15px;
  font-weight: var(--regular);
  border-top: 2px solid var(--primary1);
  width: 100%;
  z-index: 3;
  padding-top: 20px;
}

footer .bottom .links {
  color: var(--primary1);
}

footer .fixed img {
  width: 110%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -10;
  opacity: 0.2;
}

@media (max-width: 1300px) {
  .about_land {
    padding: 60px 20px;
  }

  .why-us .fixed img {
    position: absolute;
    top: 0;
    margin-top: 1227px;
    right: 0;
    width: 400px;
    opacity: 0.4;
    z-index: -1;
    opacity: 0.2;
  }
}

@media (max-width: 1150px) {
  .home-hero {
    width: 100%;
    padding: 50px;
    padding-top: 150px;
    padding-bottom: 150px;
    border-bottom-right-radius: 200px;
    gap: 20px;
  }

  .home-hero .text {
    max-width: 400px;
  }

  .home-hero .text h1 {
    font-size: 45px;
  }

  .home-hero .text h1 span {
    color: var(--primary1);
  }

  .home-hero .text p {
    font-size: 22px;
    font-weight: var(--regular);
    font-family: var(--font);
    color: rgb(64, 64, 64);
  }

  .home-hero .image {
    width: 100%;
    height: 100%;
  }

  .home-hero .image img {
    width: 100%;
    height: max-content;
  }

  .service_land {
    padding: 30px;
  }
}

@media (max-width: 1000px) {
  .home-hero {
    padding: 50px;
    padding-top: 150px;
    padding-bottom: 100px;
    border-bottom-right-radius: 140px;
  }

  .home-hero .text {
    max-width: 350px;
  }

  .home-hero .text h1 {
    font-size: 36px;
  }

  .about_land .text {
    max-width: 300px;
  }

  .about_land .text p {
    font-size: 13px;
  }

  .about_land .text span {
    font-size: 20px;
  }

  .about_land .text h1 {
    font-size: 14px;
  }

  .why-us {
    padding: 30px;
  }

  .why-us .frame {
    padding: 20px;
  }

  .why-us .fixed img {
    margin-top: 1130px;
  }

  .service_land .image {
    height: 500px;
    width: 400px;
  }

  .service_land .text {
    max-width: 400px;
  }

  .service_about .text {
    max-width: 500px;
  }

  .service_land .text .text-top h1 {
    font-size: 18px;
  }

  .service_land .text .text-top p {
    font-size: 60px;
  }

  .service_land .text .mySwiper {
    max-width: 400px;
  }

  .service_land .text .mySwiper .gap {
    display: flex;
    justify-content: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .service_land .text .mySwiper h1 {
    font-size: 20px;
  }

  .service_land .text .mySwiper p {
    font-size: 20px;
  }
  
  .about_about .text {
    gap: 20px;
    padding: 0px 30px;
  }

  .about_about .mission {
    padding: 30px;
  }

  .service_about{
    flex-direction: column-reverse;
  }

  .service_about .image{
    width: 100%;
  }

  .service_about .text{
    max-width: 100%;
  }

  .service_service {
    padding: 30px;
    gap: 50px;
  }
  
  .service_service .service .text {
    max-width: 300px;
  }
}

@media (max-width: 950px) {
  .why-us .fixed img {
    margin-top: 1080px;
  }
}

@media (max-width: 850px) {
  .home-hero .text {
    max-width: 280px;
    gap: 20px;
  }

  .home-hero .text h1 {
    font-size: 30px;
  }

  .home-hero .text p {
    font-size: 15px;
  }

  .home-hero .top img {
    width: 80%;
  }

  .why-us .fixed img {
    margin-top: 1058px;
  }

  .service_land .image {
    height: 500px;
    width: 400px;
  }

  .service_land .text {
    max-width: 300px;
    gap: 20px;
    justify-content: center;
  }

  .service_land .text .text-top h1 {
    font-size: 16px;
  }

  .service_land .text .text-top p {
    font-size: 40px;
  }

  .service_land .text .mySwiper {
    max-width: 300px;
  }

  .service_land .text .mySwiper h1 {
    font-size: 17px;
  }

  .service_land .text .mySwiper p {
    font-size: 17px;
  }

  .service_about .image{
    width: 100%;
  }

  .service_about .text{
    max-width: 100%;
  }

  .equipment {
    width: 100%;
    padding: 30px;
  }
}

@media (max-width: 800px) {
  .why-us .fixed img {
    margin-top: 1024px;
  }
}

@media (max-width: 750px) {
  .home-hero {
    justify-content: space-between;
    flex-direction: column;
    min-width: 100%;
    gap: 40px;
  }

  .home-hero .text {
    max-width: 100%;
  }

  .home-hero .text h1 {
    font-size: 60px;
  }

  .home-hero .text p {
    font-size: 20px;
  }

  .about_land {
    flex-direction: column;
    width: 100%;
    padding: 50px 20px;
    gap: 40px;
  }

  .about_land .text {
    align-items: flex-start;
    flex-direction: column;
    max-width: 100%;
  }

  .about_land .text p {
    font-size: 16px;
  }

  .about_land .text span {
    font-size: 22px;
  }

  .about_land .text h1 {
    font-size: 17px;
  }

  .about_land .image {
    width: 100%;
    height: 100%;
  }

  .why-us .frame {
    padding: 30px 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border: none;
    gap: 20px;
    flex-direction: column;
  }

  .why-us .frame .container {
    width: 100%;
  }

  .why-us .fixed img {
    margin-top: 2250px;
    width: 100%;
  }

  .service_land {
    padding-top: 70px;
    flex-direction: column-reverse;
  }

  .service_land .image {
    height: auto;
    width: 100%;
    align-items: flex-start;
  }

  .service_land .image img {
    width: 100%;
  }

  .service_land .text {
    max-width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  .service_land .text .text-top h1 {
    font-size: 18px;
  }

  .service_land .text .text-top p {
    font-size: 60px;
  }

  .service_land .text .mySwiper {
    min-width: 100%;
    max-width: 100%;
  }

  .equipment {
    flex-direction: column;
    width: 100%;
  }

  .hero_about {
    display: none;
  }

  .bubble {
    height: 100px;
    width: 100px;
  }

  .bubble:after {
    height: 80px;
    width: 80px;
  }
  
  .about_about .text {
    flex-direction: column;
  }
  
  .about_about .text p{
    width: 100%;
  }

  .about_about .mission {
    justify-content: center;
    align-items: center;
  }

  .about_about .mission strong{
    text-align: center;
    line-height: 20px;
    font-size: 16px;
  }

  .service_service .service{
    flex-direction: column;
  }

  .service_service .service .image{
    width: 100%;
  }
  
  .service_service .service .text {
    max-width: 100%;
  }

  .hero_contact {
    display: none;
  }

  .form_container{
    padding: 30px;
    padding-top: 100px;
  }
}

@media (max-width: 600px) {
  .home-hero .text h1 {
    font-size: 40px;
  }

  .home-hero .text p {
    font-size: 15px;
  }

  .why-us .frame {
    padding: 30px 50px;
  }

  .why-us .fixed img {
    margin-top: 2100px;
  }

  footer {
    padding: 30px 20px;
    padding-top: 60px;
  }
}

@media (max-width: 550px) {
  .bars i {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 25px;
  }

  nav .logo {
    z-index: 1;
  }

  .logo .link img {
    width: 30px;
  }

  nav ul {
    gap: 40px;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: linear-gradient(#65994039 0%, #65994039 59%, #659940 90%),
      url(../public/images/bg.jpg);
    background-size: cover;
  }

  nav .open {
    display: flex;
  }

  nav .close {
    display: none;
  }

  nav ul li .link {
    font-size: 20px;
  }

  nav ul li .home {
    display: flex;
  }

  .why-us .frame {
    padding: 0px;
  }

  .why-us {
    padding: 30px;
  }
}

@media (max-width: 450px) {
  .home-hero {
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 50px;
    border-bottom-right-radius: 60px;
  }

  .home-hero .text h1 {
    font-size: 34px;
  }

  .home-hero .text button {
    min-width: 150px;
  }

  .why-us .fixed img {
    margin-top: 2000px;
  }

  .service_land .text .text-top p {
    font-size: 40px;
  }

  footer .top .image {
    display: none;
  }
  
  .about_about .text p{
    font-size: 15px;
  }
}

@media (max-width: 420px) {
  .why-us .fixed img {
    margin-top: 2040px;
  }

  .bubble {
    height: 80px;
    width: 80px;
  }

  .bubble:after {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 330px) {
  .home-hero {
    padding: 10px;
    padding-top: 100px;
    padding-bottom: 50px;
    gap: 20px;
  }

  .home-hero .text {
    gap: 15px;
  }

  .home-hero .text h1 {
    font-size: 28px;
  }

  .home-hero .text button {
    min-width: 100px;
  }

  .why-us .fixed img {
    margin-top: 2030px;
  }

  .equipment .image {
    width: 100%;
    padding: 30px;
  }

  .equipment .image img {
    width: 100%;
  }
}
